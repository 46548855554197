<template>
    <div class="form">
        <div class="background background_blue background_lawyer">
            <div class="background__image background__image_lawyer"></div>
        </div>

        <div class="wrapper">
            <div class="content">
                <div class="content__container lawyers">
                    <p class="lawyers__title">{{ 'lawyers_title' | localize }}</p>
                    <p class="lawyers__subtitle">{{ 'lawyers_subtitle' | localize }}</p>
                    <TextBlock class="lawyers__text" :id="23" />
                    <router-link class="button button__form" :to="{name: 'Lawyers Questionnaire'}">{{ "anket" | localize }}</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TextBlock from '@/components/Text.vue'

export default {
  name: 'Lawyer',
  components: { TextBlock },
}
</script>